import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StorageService } from './storage.service';
import { Utm } from '../../dataset/Utm';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UtmTagsService {
  private readonly storageContext = inject(StorageService);
  private readonly route = inject(ActivatedRoute);

  constructor() {}

  getUtmTags(): Utm {
    const utm_source = this.storageContext.getItem('utm_source') ?? '';
    const utm_medium = this.storageContext.getItem('utm_medium') ?? '';
    const utm_campaign = this.storageContext.getItem('utm_campaign') ?? '';
    const utm_term = this.storageContext.getItem('utm_term') ?? '';
    const utm_content = this.storageContext.getItem('utm_content') ?? '';

    return {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };
  }

  initUtmTagsConversion() {
    if (this.hasUtmTagsInRoute()) {
      this.route.queryParamMap
        .pipe(
          untilDestroyed(this),
          take(1),
          map(queryParamsMap => {
            const utmTags = this.getUtmTagsFromRoute(queryParamsMap);

            this.setUtmTagsToStorage(utmTags);
          })
        )
        .subscribe();
    }
  }

  private hasUtmTagsInRoute(): boolean {
    return window.location.href.includes('utm');
  }

  private setUtmTagsToStorage(utmTags: Utm) {
    for (const [key, value] of Object.entries(utmTags)) {
      this.storageContext.setItem(key, value);
    }
  }

  private getUtmTagsFromRoute(queryParamMap: ParamMap): Utm {
    const utm_source = queryParamMap.get('utm_source') ?? '';
    const utm_medium = queryParamMap.get('utm_medium') ?? '';
    const utm_campaign = queryParamMap.get('utm_campaign') ?? '';
    const utm_term = queryParamMap.get('utm_term') ?? '';
    const utm_content = queryParamMap.get('utm_content') ?? '';

    return {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };
  }
}
